import React from 'react'
import classnames from 'classnames'
import strftime from 'strftime'
import {
  CampaignHistory,
  None,
} from 'components/ContactPanel/ContactCampaignHistory'
import ContactNotes from 'components/ContactPanel/ContactNotes'
import { AHIcon } from 'components/Icons/AHIcon/AHIcon'
import Tooltip from 'components/Tooltip/Tooltip'
import 'components/ContactPanel/ContactPanel.scss'
import { AuthStatus, IContactLabel, IFullContact } from 'store/contacts/reducer'
import { Hint } from 'components/Hint/Hint'
import { formatPhone } from 'util/phone'
import { Button } from 'components/Button/Button'
import {
  contactIsOptedOut,
  contactIsPaused,
  ContactPanelContent,
} from 'components/ContactPanel/ContactPanel'
import { Link } from 'util/routing'
import { CONTACTS } from 'const/routes'
import { ContactAttributeValueSection } from 'components/ContactPanel/ContactAttributeValues'
import { LocationDescriptor } from 'history'
import * as Api from 'api'
import { isRight } from 'fp-ts/lib/Either'
import * as Raven from '@sentry/browser'
import { TransportEnum, TransportId } from 'store/transport'
import { assertNever } from 'util/exhaustiveness'
import moment from 'moment'
import { useFeatures, useSelector } from 'util/hooks'
import { getInstitutionDateFormat } from 'store/triage/institution/selectors'
import { ContactAttributeType } from 'store/personalization/contactAttributes/selectors'

import PermissionsGuard from 'util/permissions/PermissionsGuard'
import { PERMISSIONS } from 'util/permissions/permissions'
import { IntlPhoneNumber } from 'components/PurchasePhoneNumbersForm/PurchasePhoneNumbersForm'
import { ProfileIcon } from 'components/Icons/ProfileIcon/ProfileIcon'
import { CloseButtonIcon } from 'components/Icons/CloseButtonIcon/CloseButtonIcon'
import scssVariables from 'scss/_variables.scss'
import { Circle } from 'components/Circle/Circle'
import { isString, isEmpty } from 'lodash'
import {
  IMessagingStatusOption,
  MessagingStatusOptions,
  SMSOptInWarning,
} from 'components/ContactPanel/EditableContactPanel'
import pluralize from 'pluralize'
import AdmithubOnly from 'components/AdmithubOnly/AdmithubOnly'
import { ConversationSummary } from 'components/ContactPanel/ConversationSummary'

interface ILabelCollectionProps {
  labels: IContactLabel[]
}

const ContactLabel = ({ label }: { label: IContactLabel }) => (
  <p className="py-1 badge font-weight-normal badge-pill text-ellipsis border border-mainstay-dark-blue-80 bg-mainstay-dark-blue-05 mr-2 text-mainstay-dark-blue">
    {label.text}
  </p>
)

const EXPANDABLE_LABELS_THRESHOLD = 5
const LABELS_TIP_THRESHOLD = 15

function ContactLabelsView({ labels }: ILabelCollectionProps) {
  const [viewExpanded, toggleViewExpanded] = React.useState(false)
  if (labels.length === 0) {
    return null
  }
  const labelsToShow = viewExpanded
    ? labels
    : labels.slice(0, EXPANDABLE_LABELS_THRESHOLD)

  return (
    <div className="d-flex flex-wrap align-items-end">
      {labelsToShow.map(label => (
        <ContactLabel label={label} key={label.id} />
      ))}
      <div className="d-flex flex-column align-items-center mb-3">
        {viewExpanded && labels.length > LABELS_TIP_THRESHOLD && (
          <Hint>
            <p>
              You have lots of labels. You can manage them on the{' '}
              <Link
                className="link-unstyled text-underline"
                to="/contacts/label-manager/">
                Contact Label Manager
              </Link>{' '}
              Page
            </p>
          </Hint>
        )}
        {labels.length > EXPANDABLE_LABELS_THRESHOLD && (
          <Link
            to="#"
            className="expand-labels-link"
            onClick={e => {
              e.preventDefault()
              toggleViewExpanded(!viewExpanded)
            }}>
            {viewExpanded ? (
              <>
                <span className="fs-0_8rem">Collapse labels</span>
                <AHIcon name="arrow_drop_up" />{' '}
              </>
            ) : (
              <>
                <span className="fs-0_8rem">
                  + {labels.length - EXPANDABLE_LABELS_THRESHOLD} more{' '}
                  {pluralize(
                    'label',
                    labels.length - EXPANDABLE_LABELS_THRESHOLD
                  )}
                </span>
                <AHIcon name="arrow_drop_down" />
              </>
            )}
          </Link>
        )}
      </div>
    </div>
  )
}

interface IContactFieldViewProps {
  readonly name: string
  readonly value: string | string[] | React.ReactNode
  readonly info?: string | JSX.Element
  readonly dataType?: ContactAttributeType
}

const getDisplayValueBasedOnType = (
  value: IContactFieldViewProps['value'],
  dataType: IContactFieldViewProps['dataType'],
  dateFormat: string
) => {
  if (!isString(value)) {
    return value
  }
  if (
    dataType === ContactAttributeType.DATE &&
    moment(value, 'YYYY-MM-DD').isValid()
  ) {
    // Note (Manan): For date values, we need to convert ISO-8601 dates into YYYY/MM//DD
    // format (slash instead of hyphens), because most JS Date parsers treat ISO-8601 as
    // always UTC, which then gets converted into the user's timezone for display, causing
    // the date to be wrong if you're west of UTC. Slashes keep timezone consistent with
    // the display. These are static date strings where the timezome/hours are irrelevant.
    return strftime(dateFormat, new Date(value.replace(/-/g, '/')))
  }
  if (dataType === ContactAttributeType.URL) {
    return (
      <a target="_blank" href={value}>
        {value}
      </a>
    )
  }
  return value
}

export const ContactFieldView = ({
  name,
  value,
  info,
  dataType,
}: IContactFieldViewProps) => {
  const dateFormat = useSelector(getInstitutionDateFormat)

  return (
    <div className="row gx-1">
      <h5 className="col col-6 text-wrap">
        {name}
        {info && (
          <Tooltip content={info}>
            <AHIcon name="info_outline" className="align-tooltip-icon" />
          </Tooltip>
        )}
      </h5>
      <div className="col col-6 text-truncate">
        {getDisplayValueBasedOnType(value, dataType, dateFormat)}
      </div>
    </div>
  )
}

export function getTransportName(transport: TransportId | undefined): string {
  switch (transport) {
    case 'facebook':
      return 'Facebook'
    case 'twilio':
      return 'SMS'
    case 'web':
      return 'Webchat'
    case 'slack':
      return 'Slack'
    case undefined:
      return '-'
    default:
      return assertNever(transport)
  }
}

const ReadOnlyContactPanelHeader = ({
  onClickEdit,
  closeReturnLocation,
  onClose,
}: {
  onClickEdit?: () => void
  onClose?: () => void
  closeReturnLocation?: LocationDescriptor
}) => {
  return (
    <div className="min-height-50px w-100 px-4 position-relative border-bottom border-mainstay-dark-blue-20 d-flex justify-content-between pb-2 flex-shrink-0">
      <div className="d-flex align-items-center">
        <ProfileIcon className="fill-mainstay-dark-blue-50 mr-2" />
        <h5 className="m-0">Contact Profile</h5>
      </div>
      <div className="d-flex justify-content-between">
        <PermissionsGuard
          permission={PERMISSIONS.CONTACT.EDIT}
          placement="bottom">
          {onClickEdit && (
            <Button
              eventAction="click"
              eventObject="edit"
              className="text-mainstay-dark-blue-80 hover-text-mainstay-dark-blue bg-transparent"
              onClick={onClickEdit}>
              <AHIcon name="edit" />
            </Button>
          )}
        </PermissionsGuard>
        {closeReturnLocation && (
          <Link
            eventAction="click"
            eventObject="close"
            to={closeReturnLocation}
            className="text-mainstay-dark-blue-80 hover-text-mainstay-dark-blue text-decoration-none mt-2">
            <AHIcon name="close" />
          </Link>
        )}
        {onClose && (
          <Button
            eventAction="click"
            eventObject="close"
            className=" bg-transparent"
            onClick={onClose}>
            <CloseButtonIcon className="fill-mainstay-dark-blue-80 pointer" />
          </Button>
        )}
      </div>
    </div>
  )
}

interface IContactStatusBadgeProps {
  readonly textColor: string
  readonly bgColor: string
  readonly name: string
  readonly className?: string
}

export function ContactStatusBadge({
  className,
  name,
  textColor,
  bgColor,
}: IContactStatusBadgeProps) {
  return (
    <div
      className={classnames(
        'contact-status-badge',
        { [`bg-${bgColor}`]: !!bgColor },
        className
      )}>
      <span className={classnames({ [`text-${textColor}`]: !!textColor })}>
        {name}
      </span>
    </div>
  )
}

interface IReadOnlyContactPanelProps {
  readonly contact: IFullContact
  readonly closeReturnLocation?: LocationDescriptor
  readonly onClose?: () => void
  readonly className?: string
  readonly onClickEdit?: () => void
  readonly contactIsPermitted: boolean
}

const HumanizedAuthStatus = (authStatus?: string) => {
  switch (authStatus) {
    case AuthStatus.authenticated:
      return 'Authenticated'
    case AuthStatus.authfailed:
      return 'Authentication Failed'
    case AuthStatus.expired:
      return 'Authentication Expired'
    case AuthStatus.none:
    default:
      return 'Not Authenticated'
  }
}

type ContactStatusKey = 'isPaused' | 'isTestUser' | 'isPermitted' | 'isOptedOut'

const ReadOnlyContactProfileSection = ({
  contact,
  contactStatus,
}: {
  contactStatus: { [k in ContactStatusKey]: boolean }
  contact: IFullContact
}) => {
  const { hasFeature, FeaturesType } = useFeatures()
  return (
    <div className="w-100 px-4 position-relative border-bottom border-mainstay-dark-blue-20 py-3">
      <div className="d-flex justify-content-start align-items-center">
        <h3 className="mb-1 mr-2">
          {contact?.name?.first} {contact?.name?.last}{' '}
          {contact?.name?.preferred ? `(${contact?.name?.preferred})` : ''}
        </h3>
        <Tooltip content="If on, indicates that contact will appear in lists of available contacts to send test campaigns and scripts to.">
          <div>
            {contactStatus.isTestUser ? (
              <ContactStatusBadge
                className="mb-2 align-lozenge"
                name="TEST USER"
                textColor="mainstay-dark-blue"
                bgColor="mainstay-dark-blue-10"
              />
            ) : null}
          </div>
        </Tooltip>
        <Tooltip content="The contact is not permitted to engage with or receive campaigns from the bot.">
          <div>
            {!contactStatus.isPermitted ? (
              <ContactStatusBadge
                className="mb-2 align-lozenge"
                name="ARCHIVED"
                textColor="mainstay-dark-blue"
                bgColor="mainstay-dark-blue-10"
              />
            ) : null}
          </div>
        </Tooltip>
      </div>
      <div>
        <p className="mb-0">
          {hasFeature(FeaturesType.INTL_TEXTING_V1) && contact.phone ? (
            <IntlPhoneNumber
              phoneNumber={{
                phoneE164: `+${contact.countryCallingCode}${contact.phone}`,
              }}
            />
          ) : (
            formatPhone(
              contact.phone,
              contact.transport === TransportEnum.twilio
                ? 'Missing phone number'
                : 'Phone number not yet collected'
            )
          )}
        </p>
        {contact.email && <p>{contact.email}</p>}
      </div>
    </div>
  )
}

const EMPTY_VALUE = '--'

const ContactDefaultFields = ({ contact }: { contact: IFullContact }) => {
  const [showAuth, setShowAuth] = React.useState(false)
  React.useEffect(() => {
    Api.getInstitutionContactAuthSettings().then(res => {
      if (isRight(res)) {
        setShowAuth(res.right.auth_settings.enabled)
      } else {
        Raven.captureMessage(
          'Failed to load authentication settings on contact panel',
          Raven.Severity.Error
        )
        setShowAuth(false)
      }
    })
  }, [setShowAuth])
  const authExpiresOn = contact.authentication?.expires
    ? contact.authentication.expires.substring(0, 10)
    : EMPTY_VALUE
  return (
    <>
      <ContactFieldView
        name="AI Disabled"
        value={contact.aiDisabled ? 'True' : 'False'}
        info="If enabled, the AI bot will not respond to incoming messages from this contact or trigger escalations."
      />
      <ContactFieldView
        name="Channels"
        value={
          contact?.transports && contact.transports.length
            ? contact.transports.map(transport => {
                const messagingStatus = MessagingStatusOptions.find(
                  o => o.value === contact.messagingStatus[transport]
                )
                return (
                  <div
                    className="d-flex align-items-center flex-wrap justify-content-between"
                    key={transport}>
                    <div className="d-flex align-items-center">
                      {getTransportName(transport)}{' '}
                      {transport === 'twilio' &&
                        contact.previouslyOptedOutOfSMS && <SMSOptInWarning />}
                    </div>
                    {messagingStatus && getStatusBadge(messagingStatus.value)}
                  </div>
                )
              })
            : []
        }
      />
      <ContactFieldView
        name="Contacted"
        value={contact._contactSettings?.contacted ? 'True' : 'False'}
        info="If true, the contact has received at least one message from Mainstay."
      />
      <ContactFieldView name="CRM ID" value={contact.crmId || EMPTY_VALUE} />
      <ContactFieldView
        name="Date Added"
        value={
          contact.created
            ? new Date(contact.created).toLocaleDateString()
            : 'Unknown'
        }
      />
      <ContactFieldView
        name="Delivery Failure Count"
        value={String(contact.deliveryFailureCount)}
      />
      <ContactFieldView
        name="Enrollment ID"
        value={contact.enrollmentId || EMPTY_VALUE}
      />
      <ContactFieldView
        name="Invalid Phone"
        value={
          contact._contactSettings?.nonWorkingNumber ||
          contact._contactSettings?.wrongNumber
            ? 'True'
            : 'False'
        }
        info="If true, indicates that the contact's current phone number has been deemed either to be non-working or erroneous."
      />
      <ContactFieldView name="Language" value={contact.language} />
      {showAuth && (
        <>
          <ContactFieldView
            name="Authentication"
            value={HumanizedAuthStatus(contact.authentication.validatedStatus)}
          />
          <ContactFieldView
            name="Authentication expires on"
            value={authExpiresOn}
          />{' '}
        </>
      )}
      <ContactFieldView
        name="Previous Phone"
        value={contact.previousPhone || EMPTY_VALUE}
        info="If the contact has changed phone numbers, this is the most recently used number before their current number."
      />
      <ContactFieldView
        name="Test User"
        value={contact._testUser ? 'True' : 'False'}
        info="If on, indicates that contact will appear in lists of available contacts to send test campaigns and scripts to."
      />
    </>
  )
}

export const ReadOnlyContactPanel = ({
  contact,
  className,
  closeReturnLocation,
  onClickEdit,
  onClose,
  contactIsPermitted,
}: IReadOnlyContactPanelProps) => {
  // Generates links to imports displayed at bottom of panel
  const contactImportElements =
    contact.importIdsToLabel && !isEmpty(contact.importIdsToLabel) ? (
      Object.entries(contact.importIdsToLabel).map(importArr => {
        const [label, id] = importArr
        if (id === 'api-import') {
          return <div key={label}>{label}</div>
        }
        const path = CONTACTS.IMPORT_DETAIL.replace(':id', id)
        return (
          <Link to={path} className="d-block" key={id}>
            {label}
          </Link>
        )
      })
    ) : (
      <None />
    )

  return (
    <ContactPanelContent
      className="pt-4 w-100"
      header={
        <>
          <ReadOnlyContactPanelHeader
            onClickEdit={onClickEdit}
            closeReturnLocation={closeReturnLocation}
            onClose={onClose}
          />
          <ReadOnlyContactProfileSection
            contact={contact}
            contactStatus={{
              isOptedOut: contactIsOptedOut(contact),
              isPaused: contactIsPaused(contact),
              isTestUser: contact._testUser,
              isPermitted: contactIsPermitted,
            }}
          />
          {contact.authentication?.authedContactId &&
            (contact.authentication?.authedContactId !== contact.id ? (
              <p className="ml-3 pb-2 ">
                Linked to{' '}
                <Link
                  to={`/contacts/${contact.authentication.authedContactId}`}>
                  {contact.authentication.authedContactName}{' '}
                </Link>
              </p>
            ) : null)}
        </>
      }>
      <div
        className={classnames(
          'contact-panel overflow-y-auto h-100 w-100 border-bottom border-mainstay-dark-blue-20',
          className
        )}>
        {contact.contactLabels.length > 0 && (
          <CollapsibleContactPanelSection title="Labels">
            <ContactLabelsView labels={contact.contactLabels} />
          </CollapsibleContactPanelSection>
        )}

        <CollapsibleContactPanelSection title="Notes">
          <ContactNotes />
        </CollapsibleContactPanelSection>

        <CollapsibleContactPanelSection
          info="These fields are standard to all contacts."
          title="Default Contact Fields">
          <ContactDefaultFields contact={contact} />
        </CollapsibleContactPanelSection>
        <AdmithubOnly>
          <CollapsibleContactPanelSection title="Conversation Summary">
            <ConversationSummary
              contactId={contact.id}
              conversationSummary={contact.conversationSummary}
            />
          </CollapsibleContactPanelSection>
        </AdmithubOnly>

        {!!contact.attributeValues?.length && (
          <CollapsibleContactPanelSection
            info="Go to Contact Settings to add or edit the fields in this section."
            title="Custom Contact Fields">
            <ContactAttributeValueSection
              contactAttributeValues={contact.attributeValues}
            />
          </CollapsibleContactPanelSection>
        )}

        <CollapsibleContactPanelSection title="Campaign Scripts">
          <CampaignHistory history={contact.campaignHistory} />
        </CollapsibleContactPanelSection>

        <CollapsibleContactPanelSection title="Recent Import History">
          {contactImportElements}
        </CollapsibleContactPanelSection>
        <div className="w-100 d-flex justify-content-center pt-4 pb-3">
          <Circle radius={3} fill={scssVariables.mainstayDisabledGray} />
        </div>
      </div>
    </ContactPanelContent>
  )
}

interface ICollapsibleContactPanelSectionProps {
  title: string
  info?: string
  children: React.ReactNode
  startExpanded?: boolean
}

const CollapsibleContactPanelSection = ({
  title,
  info,
  children,
  startExpanded = true,
}: ICollapsibleContactPanelSectionProps) => {
  const [expanded, setExpanded] = React.useState(startExpanded)
  return (
    <div className="px-3 pb-4">
      <div
        className="d-flex pointer align-items-center mb-2"
        onClick={() => setExpanded(!expanded)}>
        <AHIcon name={expanded ? 'expand_more' : 'navigate_next'} />
        <div className="d-flex">
          <h4 className="mb-0">{title}</h4>
          {info && (
            <Tooltip content={info}>
              <div className="align-tooltip-section-level">
                <AHIcon name="info_outline" className="ml-1" />
              </div>
            </Tooltip>
          )}
        </div>
      </div>
      <div className="mx-4 collapsible-section-content">
        {expanded && children}
      </div>
    </div>
  )
}

function getStatusBadge(status: IMessagingStatusOption['value']) {
  switch (status) {
    case 'OptedIn':
      return (
        <ContactStatusBadge
          name="OPTED IN"
          bgColor="mainstay-success-100"
          textColor="mainstay-success-700"
        />
      )
    case 'Paused':
      return (
        <ContactStatusBadge
          name="PAUSED"
          bgColor="mainstay-warning-100"
          textColor="mainstay-warning-700"
        />
      )
    case 'Stopped':
      return (
        <ContactStatusBadge
          name="OPTED OUT"
          bgColor="mainstay-error-100"
          textColor="mainstay-error-600"
        />
      )
  }
}

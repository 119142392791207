import * as api from 'api'
import { detailOrDefault, toastOnHttpError500or400 } from 'api/http'
import { AxiosError, CancelToken } from 'axios'
import { IContactFormData } from 'components/ContactPanel/EditableContactPanel'
import { push } from 'connected-react-router'
import { CONTACTS } from 'const/routes'
import { isRight } from 'fp-ts/lib/Either'
import { toast } from 'mainstay-ui-kit/MainstayToast/MainstayToast'
import { updateConversationDetailsContact } from 'page/conversations-v2/ConversationsDetail/actions'
import {
  addNoteToContact,
  archiveOrUnarchiveContacts,
  createContact,
  deleteContactNote,
  editContactNote,
  fetchContact,
  generateConversationSummary,
  getContactCounts,
  updateArchivePolling,
  updateAttributeValues,
  updateContact,
} from 'store/contacts/actions'
import { IArchiveActionType, IContactNote } from 'store/contacts/reducer'
import { Dispatch } from 'store/store'
import { getQueryFilters } from 'util/queryFilters'
import { timeout } from 'util/timeout'

export const fetchContactAsync = (dispatch: Dispatch) => {
  return (id: string) => {
    dispatch(fetchContact.request({ id }))
    return api
      .fetchContact(id)
      .then(response => {
        dispatch(fetchContact.success({ contact: response.data }))
      })
      .catch((err: AxiosError) => {
        dispatch(fetchContact.failure(err))
      })
  }
}

export const updateContactAsync = (dispatch: Dispatch) => {
  return (id: string, data: api.IUpdateContactInfoPanelRequest['data']) => {
    dispatch(updateContact.request({ id, data }))
    dispatch(updateAttributeValues.request({ id, data }))
    return api
      .updateContactInfoPanel({ contactId: id, data })
      .then(response => {
        dispatch(updateContact.success({ contact: response.data }))
        dispatch(
          updateAttributeValues.success({
            attributeValues: response.data.attributeValues || [],
          })
        )
        dispatch(
          updateConversationDetailsContact({
            name: `${data.firstName || ''} ${data.lastName || ''}`,
          })
        )
        return 'ok' as const
      })
      .catch((err: AxiosError) => {
        dispatch(updateContact.failure(err))
        dispatch(updateAttributeValues.failure(err))
        const errorMessage = detailOrDefault(err, 'Failed to save contact')
        toast(errorMessage, { type: 'error' })
        return 'error' as const
      })
  }
}

export const addNoteToContactAsync = (dispatch: Dispatch) => {
  return (contactId: string, note: IContactNote) => {
    dispatch(addNoteToContact.request({ id: contactId, note }))
    return api
      .addNoteToContact({ contactId, note })
      .then(response => {
        dispatch(addNoteToContact.success({ notes: response.data }))
      })
      .catch((err: AxiosError) => {
        dispatch(addNoteToContact.failure(err))
        toastOnHttpError500or400(err)
      })
  }
}

export const editContactNoteAsync = (dispatch: Dispatch) => {
  return (contactId: string, noteId: string, newText: string) => {
    dispatch(editContactNote.request({ id: contactId, noteId, newText }))
    api
      .editContactNote({ contactId, noteId, newText })
      .then(response => {
        dispatch(editContactNote.success({ notes: response.data }))
      })
      .catch((err: AxiosError) => {
        dispatch(editContactNote.failure(err))
        toastOnHttpError500or400(err)
      })
  }
}

export const deleteContactNoteAsync = (dispatch: Dispatch) => {
  return (contactId: string, noteId: string) => {
    dispatch(deleteContactNote.request({ id: contactId, noteId }))
    api
      .deleteContactNote({ contactId, noteId })
      .then(response => {
        dispatch(deleteContactNote.success({ notes: response.data }))
      })
      .catch((err: AxiosError) => {
        dispatch(deleteContactNote.failure(err))
      })
  }
}

export const createContactAsync = (dispatch: Dispatch) => {
  return (data: Partial<IContactFormData>) => {
    dispatch(createContact.request({ data }))
    return api
      .createContactInfoPanel(data)
      .then(response => {
        toast('Contact created successfully!', {
          type: 'success',
          options: {
            autoClose: 1000,
          },
        })
        dispatch(createContact.success({ contact: response.data }))
        dispatch(push(`${CONTACTS.INDEX}${response.data['id']}`))
      })
      .catch((err: AxiosError) => {
        dispatch(createContact.failure(err))
        toastOnHttpError500or400(err)
      })
  }
}

const pollGetContactCountsAsync = (
  taskId: string,
  cancelToken: CancelToken,
  ms: number = 500
) => (dispatch: Dispatch) => {
  api
    .pollGetContactCounts(taskId, cancelToken)
    .then(async res => {
      if (isRight(res)) {
        if (res.right.status === 'SUCCESS') {
          dispatch(getContactCounts.success(res.right))
        } else if (res.right.status === 'PENDING') {
          await timeout(ms)
          pollGetContactCountsAsync(taskId, cancelToken)(dispatch)
        }
      } else {
        dispatch(getContactCounts.failure())
      }
    })
    .catch((e: AxiosError) => {
      dispatch(getContactCounts.failure())
      toastOnHttpError500or400(e)
    })
}

export const getContactCountsAsync = (dispatch: Dispatch) => {
  return (
    cancelToken: CancelToken,
    searchQuery: string,
    contactFilterId?: number
  ) => {
    const filter = getQueryFilters(window.location)
    dispatch(
      getContactCounts.request({
        searchQuery,
        filter,
      })
    )
    return api
      .fetchContactCounts(cancelToken, searchQuery, filter, contactFilterId)
      .then(res => {
        const taskId = res.data.taskId
        pollGetContactCountsAsync(taskId, cancelToken)(dispatch)
      })
      .catch((e: AxiosError) => {
        dispatch(getContactCounts.failure())
        toastOnHttpError500or400(e)
      })
  }
}

const pollArchiveProgressAsync = (taskId: string, ms = 500) => (
  dispatch: Dispatch
) => {
  api
    .pollArchiveContactsAction(taskId)
    .then(async res => {
      if (res.data.status === 'SUCCESS') {
        dispatch(archiveOrUnarchiveContacts.success(res.data))
        toast('Archive action complete!', {
          type: 'success',
          options: { autoClose: 3000 },
        })
        dispatch(updateArchivePolling.success())
      } else {
        await timeout(ms)
        dispatch(updateArchivePolling.request(res.data))
        pollArchiveProgressAsync(taskId)(dispatch)
      }
    })
    .catch((e: AxiosError) => {
      dispatch(updateArchivePolling.failure())
      toastOnHttpError500or400(e)
    })
}

export const archiveOrUnarchiveContactsAsync = (dispatch: Dispatch) => {
  return (searchQuery: string, action: IArchiveActionType, source: string) => {
    const filter = getQueryFilters(window.location)
    dispatch(
      archiveOrUnarchiveContacts.request({
        searchQuery,
        filter,
        action,
        source,
      })
    )
    toast(
      'Archive action started. This may take a few minutes. Please do not close this page.',
      { options: { autoClose: 4000 } }
    )
    return api
      .archiveOrUnarchiveContacts(searchQuery, filter, action, source)
      .then(res => {
        const taskId = res.data.taskId
        pollArchiveProgressAsync(taskId)(dispatch)
      })
      .catch((e: AxiosError) => {
        dispatch(archiveOrUnarchiveContacts.failure(e))
        toastOnHttpError500or400(e)
      })
  }
}

export const generateConversationSummaryAsync = (dispatch: Dispatch) => {
  const errorMsg = 'There was an error generating the conversation summary.'
  return (contactId: string) => {
    dispatch(generateConversationSummary.request({ id: contactId }))
    api
      .genConversationSummary({ contactId })
      .then(res => {
        if (isRight(res)) {
          dispatch(generateConversationSummary.success({ summary: res.right }))
        } else {
          toast(errorMsg, { type: 'error' })
          dispatch(generateConversationSummary.failure())
        }
      })
      .catch(() => {
        toast(errorMsg, { type: 'error' })
        dispatch(generateConversationSummary.failure())
      })
  }
}
